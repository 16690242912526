import React from "react"
import BSForm from "react-bootstrap/Form"
import BSFormControl from "react-bootstrap/FormControl"
import branding from "../../branding/branding"
import InputField, { FeedbackInput } from "./InputField"

interface SelectOption {
    id: string
    label: string
    value: string
}

interface SelectOptionsProps {
    options: Array<SelectOption>
}

interface SelectFieldProps extends FeedbackInput {
    id: string
    name: string
    label: string
    placeholder: string
    options: Array<SelectOption>
    required?: boolean
    readonly?: boolean
    value?: string
    infoText?: string
    showNullOption?: boolean
    onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void
}

function makeNullOption(nullLabel: string) {
    return (
        <option key="" value="" id="">
            {nullLabel}
        </option>
    )
}

const SelectOptions: React.FC<SelectOptionsProps> = ({ options }) => {
    return (
        <>
            {options.map((option) => (
                <option key={option.id} value={option.value} id={option.id}>
                    {option.label}
                </option>
            ))}
        </>
    )
}

const SelectFieldO: React.FC<SelectFieldProps> = ({
    id,
    name,
    label,
    placeholder,
    options,
    required,
    readonly,
    value,
    showNullOption = true,
    error,
    infoText,
    onChange
}) => {
    return (
        <BSForm.Group>
            <BSForm.Label>
                {label}
                {required ? "*" : ""}
            </BSForm.Label>
            <BSForm.Control
                id={id}
                as="select"
                name={name}
                placeholder={placeholder}
                value={value}
                readOnly={readonly}
                disabled={readonly}
                isValid={!error}
                isInvalid={!!error}
                onChange={onChange}
            >
                {showNullOption && makeNullOption(branding.configuration.lblEmptySelect)}
                <SelectOptions options={options} />
            </BSForm.Control>
            {!error && <BSFormControl.Feedback type="valid">{infoText}</BSFormControl.Feedback>}
            <BSFormControl.Feedback type="invalid">{error}</BSFormControl.Feedback>
        </BSForm.Group>
    )
}

const SelectField: React.FC<any> = (props: any) => <InputField component={SelectFieldO} {...props} />
export default SelectField
