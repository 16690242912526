import { hookstate, Immutable, useHookstate } from "@hookstate/core"
import { Validation, validation } from "@hookstate/validation"
import { uploadDataWebService, fetchDataRest, downloadFile } from "../backendServices/backendServices"
import branding from "../branding/branding"
import { nextYear, now, startOfDay } from "../utils/Dates"
import { addValidators } from "./ExhibitorValidation"
import { mapOrganization, parseUploadXml, mapOrganizationOut, mockShowrooms } from "./ExhibitorDataMapper"
import { ServiceKeys } from "./ConfigDataState"
import { ReferenceDataState } from "./ReferenceDataState"
import {
    Coupons,
    EventDate,
    ExhibitorData,
    Job,
    Link,
    Media,
    News,
    NotificationTemplate,
    Organization,
    Person,
    PrintCatalog,
    Product,
    Showrooms,
    Trademark
} from "./ExhibitorDataTypes"
import { SubelementPackage, useSubelementPackagesState } from "./SubelementPackagesState"

export const countriesWithoutZipCode = [
    "AO",
    "AG",
    "AW",
    "BS",
    "BZ",
    "BJ",
    "BM",
    "BO",
    "BQ",
    "BW",
    "BF",
    "BI",
    "CM",
    "CF",
    "TD",
    "KM",
    "CG",
    "CD",
    "CK",
    "CI",
    "CW",
    "DJ",
    "DM",
    "TL",
    "GQ",
    "ER",
    "FJ",
    "TF",
    "GA",
    "GM",
    "GD",
    "GY",
    "HM",
    "HK",
    "KI",
    "KP",
    "LY",
    "MO",
    "ML",
    "MR",
    "NR",
    "NL",
    "NU",
    "QA",
    "RW",
    "ST",
    "SC",
    "SL",
    "SX",
    "SB",
    "SS",
    "SR",
    "SY",
    "TG",
    "TK",
    "TO",
    "TV",
    "UG",
    "AE",
    "VU",
    "YE",
    "ZW"
]

export function countryHasZipCode(countryCode: string): boolean {
    return !countriesWithoutZipCode.includes(countryCode)
}

export const EMPTY_LINK: Link = {
    id: "",
    url: "",
    urlDe: "",
    text: "",
    textDe: "",
    order: 0
}

export const EMPTY_PRODUCT: Product = {
    id: "",
    name: "",
    nameDe: "",
    initials: "",
    orderProduct: 0,
    showFrom: startOfDay(now()),
    showUntil: undefined,
    crossValidation: undefined,
    description: "",
    teaser: "",
    descriptionDe: "",
    teaserDe: "",
    logoUrl: "",
    logoFileId: "",
    backgroundImageUrl: "",
    backgroundImageFileId: "",
    tags: [],
    medias: [],
    categories: [],
    links: [],
    type: "",
    property: "",
    properties: [],
    collection: " "
}

export const EMPTY_TRADEMARK: Trademark = {
    id: "",
    name: "",
    logoUrl: "",
    logoFileId: "",
    initials: "",
    orderTrademark: 0,
    description: "",
    teaser: "",
    descriptionDe: "",
    teaserDe: "",
    tags: [],
    medias: [],
    categories: []
}

export const EMPTY_PERSON: Person = {
    id: "",
    isEditable: true,
    function: "",
    lastName: "",
    salutation: "",
    title: "",
    firstName: "",
    logoUrl: "",
    midName: "",
    logoFileId: "",
    initials: "",
    company: "",
    position: "",
    positionDe: "",
    email: "",
    phone: "",
    fax: "",
    web: "",
    orderPerson: 0,
    description: "",
    teaser: "",
    descriptionDe: "",
    teaserDe: "",
    emailVisible: true,
    phoneVisible: true
}

export const EMPTY_MEDIA: Media = {
    id: "",
    fileId: "",
    url: "",
    name: "",
    contentType: "",
    size: "",
    order: 0,
    title: "",
    titleDe: "",
    subtitle: "",
    subtitleDe: ""
}

export const EMPTY_NEWS: News = {
    id: "",
    name: "",
    nameDe: "",
    logoUrl: "",
    initials: "",
    date: new Date(),
    description: "",
    descriptionDe: "",
    teaser: "",
    teaserDe: "",
    tags: [],
    medias: [],
    categories: [],
    suppressOrganizationLinkage: false,
    type: undefined
}

export const EMPTY_EVENTDATE: EventDate = {
    id: "",
    orderEventDates: 0,
    name: "",
    nameDe: "",
    logoUrl: "",
    logoUrlDe: "",
    startdatetime: "",
    enddatetime: "",
    eventName: "",
    eventNameDe: "",
    location: "",
    locationDe: "",
    type: "",
    isListable: false,
    liveStreamingPreviewUrl: "",
    breakoutAccess: false,
    description: "",
    teaser: "",
    descriptionDe: "",
    teaserDe: "",
    persons: [],
    medias: [],
    tags: []
}

export const EMPTY_SHOWROOM: Showrooms = {
    id: "",
    orderShowrooms: 0,
    title: "",
    titleDe: "",
    logoFileId: "",
    logourl: "",
    logourlDe: "",
    accessMethod: "",
    shortDescription: "",
    shortDescriptionDe: ""
}

export const EMPTY_COUPON: Coupons = {
    id: "",
    name: "",
    nameDe: "",
    url: "",
    urlDe: "",
    logoUrl: "",
    logoFileId: "",
    descriptionTitle: "",
    descriptionTitleDe: "",
    descriptionLong: "",
    descriptionLongDe: "",
    descriptionShort: "",
    descriptionShortDe: "",
    validStart: new Date(),
    validEnd: new Date(),
    isVisible: true
}

export const EMPTY_JOB: Job = {
    id: "",
    title: "",
    titleDe: "",
    logoFileId: "",
    logoUrl: "",
    backgroundImageFileId: "",
    backgroundImageUrl: "",
    location: "",
    locationDe: "",
    employment: "",
    employmentDe: "",
    careerLevel: "",
    careerLevelDe: "",
    salaryGroup: "",
    links: [],
    requirements: "",
    requirementsDe: "",
    active: true,
    startdate: new Date(),
    updated: new Date(),
    showFrom: startOfDay(now()),
    showUntil: nextYear(new Date()),
    description: "",
    descriptionDe: "",
    homeOffice: "",
    persons: [],
    medias: [],
    categories: []
}

export const EMPTY_NOTIFICATION_TEMPLATE: NotificationTemplate = {
    id: "",
    text: "",
    trigger: {
        type: "location",
        geofences: [],
        timeFrameStart: "",
        timeFrameEnd: ""
    },
    maxUsageCount: 0,
    destinations: [],
    targetGroup: {
        interests: undefined,
        countriesFilter: undefined,
        questionFilters: undefined
    },
    action: {
        type: "",
        param: ""
    },
    localized: {
        de: {
            text: ""
        }
    },
    selector: {
        geofences: [],
        geofenceDestination: "",
        timeStart: "",
        timeEnd: "",
        questionFilters: []
    }
}

export const EMPTY_PRINTCATALOG: PrintCatalog = {
    id: "",
    logoUrl: "",
    logoFileId: "",
    fileType: "",
    nameImported: true,
    name: "",
    addressImported: true,
    address1: "",
    address2: "",
    address3: "",
    postcode: "",
    city: "",
    countryCode: "",
    contactImported: true,
    phone: "",
    email: "",
    fax: "",
    web: "",
    descriptionImported: true,
    description: "",
    descriptionDe: "",
    adPages: []
}

let initialState = {
    id: "",
    token: "",
    organization: {
        id: "",
        name: "",
        industry: "",
        city: "",
        address1: "",
        address2: "",
        address3: "",
        logoUrl: "",
        backgroundImageUrl: "",
        backgroundImageMobileUrl: "",
        backgroundImageMobileFileId: "",
        mobile: "",
        phone: "",
        fax: "",
        email: "",
        email2: "",
        postCode: "",
        countryCode: "",
        facebook: "",
        instagram: "",
        linkedIn: "",
        twitter: "",
        tiktok: "",
        pinterest: "",
        xing: "",
        youTube: "",
        foundingYear: "",
        size: "",
        web: "",
        description: "",
        teaser: "",
        descriptionDe: "",
        teaserDe: "",
        tags: [],
        headerVideoUrl: "",
        headerVideoImageUrl: "",
        stands: [],
        iframeUrl: "",
        iframeUrlDe: "",
        consentTitle: "",
        consentTitleDe: "",
        consentText: "",
        consentTextDe: "",
        links: [],
        categories: [],
        products: [],
        productsForCollections: [],
        trademarks: [],
        persons: [],
        medias: [],
        newsList: [],
        exhibitorNewsList: [],
        organizerNewsList: [],
        storyNewsList: [],
        eventDates: [],
        showrooms: [],
        goodies: [],
        notificationPromotions: [],
        jobOffers: [],
        printCatalog: EMPTY_PRINTCATALOG,
        sortName: "",
        notificationTemplates: []
    },
    organizationBackup: {
        id: "",
        name: "",
        industry: "",
        city: "",
        address1: "",
        address2: "",
        address3: "",
        logoUrl: "",
        backgroundImageUrl: "",
        backgroundImageMobileUrl: "",
        backgroundImageMobileFileId: "",
        mobile: "",
        phone: "",
        fax: "",
        email: "",
        email2: "",
        postCode: "",
        countryCode: "",
        facebook: "",
        instagram: "",
        linkedIn: "",
        twitter: "",
        tiktok: "",
        pinterest: "",
        xing: "",
        youTube: "",
        foundingYear: "",
        size: "",
        web: "",
        description: "",
        teaser: "",
        descriptionDe: "",
        teaserDe: "",
        tags: [],
        headerVideoUrl: "",
        headerVideoImageUrl: "",
        stands: [],
        iframeUrl: "",
        iframeUrlDe: "",
        consentTitle: "",
        consentTitleDe: "",
        consentText: "",
        consentTextDe: "",
        links: [],
        categories: [],
        products: [],
        productsForCollections: [],
        trademarks: [],
        persons: [],
        medias: [],
        newsList: [],
        exhibitorNewsList: [],
        organizerNewsList: [],
        storyNewsList: [],
        eventDates: [],
        showrooms: [],
        goodies: [],
        notificationPromotions: [],
        jobOffers: [],
        printCatalog: EMPTY_PRINTCATALOG,
        sortName: "",
        notificationTemplates: []
    }
}

const exhibitorDataState = hookstate<ExhibitorData, Validation>(initialState, validation())

async function loadExhibitorData(exhibitorId: string, accessToken: string) {
    const params: any = {
        accessToken: accessToken
    }
    return await fetchDataRest(`/selfservice/0/${branding.configuration.topicName}/${exhibitorId}`, params, "GET")
}

async function storeExhibitorData(
    exhibitorId: string,
    accessToken: string,
    organization: Organization,
    serviceKeys: ServiceKeys,
    refDataState: ReferenceDataState,
    subelementPackages: Immutable<SubelementPackage[]>
) {
    const params: any = {
        accessToken: accessToken
    }
    return await fetchDataRest(
        `/selfservice/0/${branding.configuration.topicName}/${exhibitorId}`,
        params,
        "POST",
        mapOrganizationOut(organization, serviceKeys, refDataState, subelementPackages)
    )
}

async function uploadExhibitorImage(
    exhibitorId: string,
    accessToken: string,
    uploadPurpose: string,
    image: File,
    onProgress: (progress: number) => void
) {
    const uploadResponse = await uploadDataWebService(
        `/uploadfile?accessToken=${accessToken}&uploadPurpose=${uploadPurpose}`,
        image,
        onProgress
    )
    return parseUploadXml(uploadResponse)
}

async function downloadExhibitorImage(path: string, filename: string) {
    downloadFile(path, filename)
}

export function useExhibitorDataState() {
    const state = useHookstate(exhibitorDataState)
    const subelementPackages = useSubelementPackagesState().getSubelementPackages()

    return {
        init(exhibitorId: string, accessToken: string, serviceKeys: ServiceKeys, refDataState: ReferenceDataState) {
            const exData = loadExhibitorData(exhibitorId, accessToken)
            exData.then((result) => {
                const organization = mapOrganization(result, serviceKeys, refDataState)
                const mappedOrganization = { ...organization, showrooms: mockShowrooms() }
                const mappedOrganizationBackup = JSON.parse(JSON.stringify(mappedOrganization))

                state.set({
                    id: exhibitorId,
                    token: accessToken,
                    organization: mappedOrganization,
                    organizationBackup: mappedOrganizationBackup
                })
                addValidators(exhibitorDataState, serviceKeys)
                return result
            })
            return exData
        },

        reinit(serviceKeys: ServiceKeys, refDataState: ReferenceDataState) {
            const exData = loadExhibitorData(state.id.get(), state.token.get())
            exData.then((result) => {
                const organization = mapOrganization(result, serviceKeys, refDataState)
                const mappedOrganization = { ...organization, showrooms: mockShowrooms() }
                const mappedOrganizationBackup = JSON.parse(JSON.stringify(mappedOrganization))

                state.set({
                    id: state.id.get(),
                    token: state.token.get(),
                    organization: mappedOrganization,
                    organizationBackup: mappedOrganizationBackup
                })
                addValidators(exhibitorDataState, serviceKeys)
                return result
            })
            return exData
        },

        save(serviceKeys: ServiceKeys, refDataState: ReferenceDataState) {
            return storeExhibitorData(
                state.id.get(),
                state.token.get(),
                JSON.parse(JSON.stringify(state.organization.get())),
                serviceKeys,
                refDataState,
                subelementPackages
            )
        },

        upload(uploadPurpose: string, image: File, onProgress: (progress: number) => void) {
            return uploadExhibitorImage(state.id.get(), state.token.get(), uploadPurpose, image, onProgress)
        },

        download(path: string, filename: string) {
            return downloadExhibitorImage(path, filename)
        },

        getOrganization() {
            return state.organization
        },

        getOrganizationBackup() {
            return state.organizationBackup
        },

        validateField(fieldName: string) {
            return state.promised ? [] : exhibitorDataState.organization.nested(fieldName as keyof Organization).errors()
        },

        getStands() {
            return state.organization.stands
        },

        getLinks() {
            return state.organization.links
        },

        getCategories() {
            return state.organization.categories
        },

        getProducts() {
            return state.organization.products
        },

        getProductsForCollections() {
            return state.organization.productsForCollections
        },

        getTrademarks() {
            return state.organization.trademarks
        },

        getPersons() {
            return state.organization.persons
        },

        getMedias() {
            return state.organization.medias
        },

        getNews() {
            return state.organization.newsList
        },

        getExhibitorNews() {
            return state.organization.exhibitorNewsList
        },

        getOrganizerNews() {
            return state.organization.organizerNewsList
        },

        getStoryNews() {
            return state.organization.storyNewsList
        },

        getEventDates() {
            return state.organization.eventDates
        },

        getShowrooms() {
            return state.organization.showrooms
        },

        getCoupons() {
            return state.organization.goodies
        },

        getNotificationTemplates() {
            return state.organization.notificationTemplates
        },

        getNotificationPromotions() {
            return state.organization.notificationPromotions
        },

        getPrintCatalog() {
            return state.organization.printCatalog
        },

        getJobs() {
            return state.organization.jobOffers
        }
    }
}
