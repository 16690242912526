import React from "react"
import BSForm from "react-bootstrap/Form"
import BSFormControl from "react-bootstrap/FormControl"
import InputField, { FeedbackInput } from "./InputField"

interface TextFieldProps extends FeedbackInput {
    id?: string
    name: string
    label: string
    placeholder?: string
    required?: boolean
    value?: string
    limit?: number
    onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void
    readonly?: boolean
}

const TextFieldO: React.FC<TextFieldProps> = ({
    id,
    name,
    label,
    placeholder,
    required,
    value,
    limit,
    readonly,
    error,
    onChange
}) => {
    return (
        <BSForm.Group>
            <BSForm.Label>
                {label}
                {required ? "*" : ""}
            </BSForm.Label>
            <BSForm.Control
                id={id}
                name={name}
                placeholder={placeholder}
                maxLength={limit}
                value={value}
                readOnly={readonly}
                isValid={!error}
                isInvalid={!!error}
                onChange={onChange}
            />
            <BSFormControl.Feedback type="invalid">{error}</BSFormControl.Feedback>
        </BSForm.Group>
    )
}

const TextField: React.FC<any> = (props: any) => <InputField component={TextFieldO} {...props} />
export default TextField
